import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Form from '../../components/Form'
import { Link } from 'gatsby'
import './index.css'

import googleMaps from '../../images/google-maps-image.jpg'
import profilePhoto from '../../images/kevin.jpg'


const AboutPage = () => (
    <Layout>
    <SEO title='Accountant in Pasadena Maryland'/>
    <section>
      <div className='about-header'>
        <div className='intro-section-left'>
          <h1 style={{color: `#B62020`}}>ABOUT ME</h1>
          <p>
          I am a practicing Certified Public Accountant with over 25 years of experience 
          providing professional services to individuals and business in various industries. I work with 
          local individuals and businesses and am committed to their ongoing success.
          <br/>
          <br/>
          I graduated with Honors from Salisbury University with a Bachelor of Science degree in Accounting 
          and have a Master of Business Administration degree from the University of Baltimore. The first 
          two years of my career were at the Office of Legislative Audits for the State of Maryland, and 
          then I spent the next sixteen years of my career with two local accounting firms. In 2013, I decided to utilize my years of providing client-focused services and open my own accounting practice.
          </p>
          <Link id='contact-me-link' to='/md-cpa/'>contact me</Link>
        </div>
        <div className='intro-section-right'>
          <div className='profile-photo-container'>
            <img src={profilePhoto} alt='Kevin Rich is an accountant in Pasadena Maryland' />
          </div>
        </div>
      </div>
    </section>

    <section>
        <div className='location-section'>
            <div className='location-section-left'>
              <div className='map-image-container'>
                <img src={googleMaps} alt='google-maps' />
              </div>
            </div>
            <div className='location-section-right'>
                <p style={{fontWeight: `bold`}}>CONVENIENTLY LOCATED OFF EXIT 19 ON MD RTE. 100</p>
                <p>8205 Green Ice Drive, Pasadena, MD 21122, US</p>
                <a id='google-maps-link' href='https://www.google.com/maps/place/Kevin+Rich,+CPA/@39.1261857,-76.5603816,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7fc05055eaaab:0x7437f9bbfd148ec8!8m2!3d39.1262104!4d-76.5582352' target="_blank" rel="noopener noreferrer">find me on google maps</a>
            </div>
        </div>
    </section>

    <section>
      <div className='contact-section'>
        <div className='contact-section-top'>
          <p style={{margin: `0`}}>CONTACT</p>
        </div>
        <div className='contact-section-bottom'>
          <div className='contact-section-bottom__left'>
            <p>
            Contact me by phone at <span style={{color: `#B62020`}}>(410)271-8259</span> 
            <br/>
            <br/>
            or
            <br/>
            <br/>
            Fill out the contact form and I'll reach out to you as soon as possible.
            </p>
          </div>
          <div className='contact-section-bottom__right'>
            <Form />
          </div>
        </div>
      </div>
    </section>
    </Layout>
)

export default AboutPage